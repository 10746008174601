/* eslint-disable camelcase */
import { createSelector } from '@reduxjs/toolkit'
import { IRootState } from 'store'
import moment from 'moment'
import { groupBy, uniqBy } from 'lodash'
import {
  CategoryFilter,
  IPolicy,
  ISectionFeature,
  ISectionFeatures,
  IGroupedFeaturesBySection,
  IGroupedPoliciesByInsurer,
  ClassLines,
  IClassLine,
  IBuyGroup,
} from '../../types'

const getFilteredFeatures = createSelector(
  (state: IRootState) => state.comparison.comparisonFeatures,
  (state: IRootState) => state.comparison.filter,
  (state: IRootState) => state.comparison.comparisonOptions,
  (comparisonFeatures, filter) => {
    const filterFeaturesArr = Object.values(filter?.filterFeature).flat()

    const filteredfeatures = comparisonFeatures.map((feature) => {
      if (!feature?.features) return null
      let filtered = [...feature.features]

      if (filterFeaturesArr.length > 0) {
        filtered = filtered.filter((feature) => filterFeaturesArr.includes(feature.id))
      }

      if (filter.category !== CategoryFilter.ALL) {
        if (filter.category === CategoryFilter.MOST_POPULAR) {
          filtered = filtered.filter((feature) => feature?.is_most_popular)
        }
        if (filter.category === CategoryFilter.WATER_PERILS) {
          filtered = filtered.filter((feature) => feature?.is_water_perils)
        }
        if (filter.category === CategoryFilter.KEY_SELLING_FEATURES) {
          filtered = filtered.filter((feature) => feature?.is_key_selling_feature)
        }
        if (filter.category === CategoryFilter.TRAINING) {
          filtered = filtered.filter((feature) => feature?.is_training)
        }
      }

      return { ...feature, features: filtered }
    })

    return filteredfeatures
  },
)

export const getGroupedFeaturesBySection = createSelector(
  (state: IRootState) => state.comparison.comparisonFeatures,
  (comparisonFeatures: ISectionFeatures[]) => {
    const groupedSectionsByCover = comparisonFeatures.map(({ features, cover_name, cover_id }) => {
      const grouped: { [any: string]: ISectionFeature[] } = groupBy(
        features,
        (feature) => feature.section,
      )
      const groupedFeaturesBySection: IGroupedFeaturesBySection[] = Object.entries(grouped)
        .map(([key, value]) => ({
          section: key,
          seq: value[0].seq,
          features: value,
        }))
        .sort((a, b) => a.seq - b.seq)

      return { cover_id, cover_name, sections: groupedFeaturesBySection }
    })

    return groupedSectionsByCover
  },
)

export const getFilteredGroupedFeaturesBySection = createSelector(
  getFilteredFeatures,
  (filteredFeatures: ISectionFeatures[]) => {
    const groupedSectionsByCover = filteredFeatures.map(({ features, cover_name, cover_id }) => {
      const grouped: { [any: string]: ISectionFeature[] } = groupBy(
        features,
        (feature) => feature.section,
      )
      const groupedFeaturesBySection: IGroupedFeaturesBySection[] = Object.entries(grouped)
        .map(([key, value]) => ({
          section: key,
          seq: value[0].seq,
          features: value,
        }))
        .sort((a, b) => a.seq - b.seq)

      return { cover_id, cover_name, sections: groupedFeaturesBySection }
    })

    return groupedSectionsByCover
  },
)

const getFilteredPolicies = createSelector(
  (state: IRootState) => state.comparison.comparisonPolicies,
  (state: IRootState) => state.comparison.filter,
  (comparisonPolicies, filter) => {
    const policies = comparisonPolicies ? [...comparisonPolicies] : []
    const filteredPoliciesArr = Object.values(filter?.filterPolicy).flat()
    let filtered = [...policies]

    if (filteredPoliciesArr.length > 0) {
      filtered = filtered.filter((policy) => filteredPoliciesArr.includes(policy.id))
    }

    if (filter?.effectiveDate) {
      filtered = filtered.filter(({ start_date, end_date }) => {
        return (
          moment(start_date).isBefore(moment(filter?.effectiveDate)) &&
          moment(end_date).add(12, 'months').isAfter(moment(filter?.effectiveDate))
        )
      })
    }

    return filtered
  },
)

export const getGroupedPoliciesByInsurer = createSelector(
  (state: IRootState) => state.comparison.comparisonPolicies,
  (state: IRootState) => state.auth?.account,
  (policies, account) => {
    const grouped: { [any: string]: IPolicy[] } = groupBy(
      policies,
      (policies) => policies.insurer?.name,
    )

    const groupedPoliciesByInsurer: IGroupedPoliciesByInsurer[] = Object.entries(grouped).map(
      ([key, value]) => ({
        insurer: key,
        insurerLogo: value[0].insurer.logo,
        insurerWebsite: value[0]?.insurer?.website,
        rating: value[0]?.underwriter?.csr,
        policies: value,
        requireApproval: value.some((v) => v.sections.some((s) => !s.is_approved)),
      }),
    )

    // This is for a viewing room user, policies need approval to be first in the list
    if (account.is_viewing_room) {
      return groupedPoliciesByInsurer.sort((a) => (a.requireApproval ? -1 : 1))
    }

    return groupedPoliciesByInsurer
  },
)

export const getFilteredGroupedPoliciesByInsurer = createSelector(
  getFilteredPolicies,
  (state: IRootState) => state.auth?.account,
  (policies, account) => {
    const grouped: { [any: string]: IPolicy[] } = groupBy(
      policies,
      (policies) => policies.insurer?.name,
    )
    const groupedPoliciesByInsurer: IGroupedPoliciesByInsurer[] = Object.entries(grouped).map(
      ([key, value]) => ({
        insurer: key,
        insurerLogo: value[0].insurer.logo,
        insurerWebsite: value[0]?.insurer?.website,
        rating: value[0]?.underwriter?.csr,
        policies: value,
      }),
    )
    // This is for a viewing room user, policies need approval to be first in the list
    if (account.is_viewing_room) {
      return groupedPoliciesByInsurer.sort((a) => (a.requireApproval ? -1 : 1))
    }

    return groupedPoliciesByInsurer
  },
)

export const getClassCategories = createSelector(
  (state: IRootState) => state?.comparison?.productClasses,
  (classLines) => {
    const allPackageSecionExcluded = classLines.map((classLine) => ({
      ...classLine,
      classes: classLine.classes.map((cl) => ({
        ...cl,
        covers: cl?.covers.filter((cover) => !cover?.name.includes(ClassLines.ALL_PACKAGE_SECTION)),
      })),
    }))
    return allPackageSecionExcluded
  },
)

export const comparisonSelectors = {
  getSelectedPolicies: createSelector(
    (state: IRootState) => state.comparison.selectedPolicyContents,
    (selectedPolicyContents) => {
      return selectedPolicyContents.map((policy) => policy.policy)
    },
  ),
  getSelectedSectionFeatures: createSelector(
    (state: IRootState) => state.comparison.comparisonFeatures,
    (state: IRootState) => state.comparison.selectedSectionCovers,
    (comparisonFeatures, selectedSectionCovers) => {
      const foundFeatures: ISectionFeature[] = []
      selectedSectionCovers.forEach((selectedFeature: ISectionFeature) => {
        comparisonFeatures?.forEach(({ features }: { features: ISectionFeature[] }) => {
          const foundFeature = features.find((feature) => feature.id === selectedFeature.id)
          if (foundFeature) foundFeatures.push(foundFeature)
        })
      })
      return foundFeatures
    },
  ),
  getSectionFeaturesContents: createSelector(
    getFilteredGroupedFeaturesBySection,
    (state: IRootState) => state.comparison.selectedPolicyContents,
    (coverSections, selectedPolicyContents) => {
      const sections = coverSections.map((cover) => {
        const featureWithContents: IGroupedFeaturesBySection[] = cover.sections.map(
          (coverFeature) => {
            const featureContentsInserted: ISectionFeature[] = coverFeature.features.map(
              (feature) => {
                const contentsObj = {}
                const contents = selectedPolicyContents.map((content) => {
                  content.featureContents.find((featureContent) => {
                    if (featureContent.feature_id === feature.id) {
                      contentsObj[content.id] = {
                        ...featureContent,
                      }
                    }
                  })
                  return { ...contentsObj[content.id], id: content.id }
                })
                return { ...feature, contents }
              },
            )

            return { ...coverFeature, features: featureContentsInserted }
          },
        )
        return { ...cover, sections: featureWithContents }
      })
      return sections
    },
  ),
  getPolicyFeaturesContents: createSelector(
    getFilteredGroupedPoliciesByInsurer,
    (state: IRootState) => state.comparison.selectedSectionCovers,
    (insurerPolicies, selectedSectionCovers) => {
      const insurerPolicyWithContents: IGroupedPoliciesByInsurer[] = insurerPolicies.map(
        (insurer) => {
          const policyFeatureContentsInserted: IPolicy[] = insurer.policies.map((policy) => {
            const contentsObj = {}
            const contents = selectedSectionCovers.map((content) => {
              content.featureContents.forEach((featureContent) => {
                if (featureContent.policy_id === policy.id) {
                  contentsObj[content.id] = {
                    ...featureContent,
                  }
                } else if (featureContent.policy_proxy_id === policy.proxy_id) {
                  contentsObj[content.id] = {
                    ...featureContent,
                  }
                }
              })
              return { ...contentsObj[content.id], id: content.id }
            })
            return { ...policy, contents }
          })

          return { ...insurer, policies: policyFeatureContentsInserted }
        },
      )
      return insurerPolicyWithContents
    },
  ),
  getProductClasses: createSelector(getClassCategories, (classes: IClassLine[]) => {
    const allClasses =
      classes &&
      classes
        ?.filter((cl) => cl.name !== ClassLines.UNCATEGORISED)
        .map((cl) => cl.classes)
        .flat()
    return uniqBy(allClasses, 'id')
  }),
  getBuyerGroups: createSelector(
    (state: IRootState) => state?.comparison?.buyGroups,
    (buyGroups) => {
      const countryGroups = groupBy(buyGroups, 'country_code')
      const countries = Object.keys(countryGroups).map((key) => ({
        value: key,
        label: buyGroups.find((buyGroup: IBuyGroup) => buyGroup?.country_code === key).country_name,
      }))
      return { countryGroups, countries }
    },
  ),
}
