/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Avatar, Tooltip } from 'antd'
import { useAppDispatch, useAppSelector } from 'store'
import { fetchBypassMyAccountUrlAsync } from 'slices/auth/api'
import auth from 'services/auth'
import { RoutePaths } from 'types/enums'
import './user-actions.less'

const UserActions: React.FC = () => {
  const dispatch = useAppDispatch()
  const byPassMyAccountUrl = useAppSelector((state) => state.auth.byPassMyAccountUrl)

  useEffect(() => {
    if (auth.isLoggedIn && !byPassMyAccountUrl) {
      dispatch(fetchBypassMyAccountUrlAsync())
    }
  }, [])

  return (
    <div className='site-header-user-actions'>
      {auth.isLoggedIn ? (
        <div className='site-header-user'>
          <Avatar className='site-header-user-avatar' shape='square'>
            {auth.session.username && auth.session.username[0]}
          </Avatar>
          <Tooltip title={byPassMyAccountUrl?.url && 'Click here to manage your account'}>
            <Button
              className='site-header-user-name'
              type='link'
              href={byPassMyAccountUrl?.url}
              target='_blank'
            >
              {auth.session.username}
            </Button>
          </Tooltip>
          <Button className='site-header-btn-logout' type='link'>
            <a href={auth.ssoLogoutUrl}>LOG OUT</a>
          </Button>
        </div>
      ) : (
        <Link to={RoutePaths.LOGIN}>
          <Button className='login-btn' type='text' size='large'>
            LOG IN
          </Button>
        </Link>
      )}
    </div>
  )
}

export default UserActions
