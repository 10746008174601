/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */

import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { message } from 'antd'

import {
  loginAsync,
  loginWithEncryptedDataAsync,
  fetchUserAccountAsync,
  resetPasswordAsync,
  fetchBypassMyAccountUrlAsync,
} from './api'

import auth from 'services/auth'
import { IRootState } from 'store'
import { IUser, IAuth } from 'types/interfaces'
import { Status } from 'types/enums'

export interface IAuthState {
  user: IAuth | null
  account: IUser | null
  loginStatus: string
  requestStatus: Status
  resetPasswordModalOpen: boolean
  byPassMyAccountUrl: { url: string } | null
}

export const initialState: IAuthState = {
  user: null,
  account: null,
  loginStatus: Status.IDLE,
  requestStatus: Status.IDLE,
  resetPasswordModalOpen: false,
  byPassMyAccountUrl: null,
}

export const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null
      auth.logout()
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
    openResetPasswordModal: (state, { payload }: PayloadAction<boolean>) => {
      state.resetPasswordModalOpen = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loginStatus = Status.LOADING
      })
      .addCase(loginAsync.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loginStatus = Status.IDLE
        message.error(payload)
      })
      .addCase(loginAsync.fulfilled, (state, { payload }: PayloadAction<any>) => {
        auth.login(
          payload.token,
          payload.user.personName.trim() ? payload.user.personName : payload.user.userName,
          payload.expiry,
        )
        state.loginStatus = Status.IDLE
        state.user = payload
      })
      .addCase(loginWithEncryptedDataAsync.pending, (state) => {
        state.loginStatus = Status.LOADING
      })
      .addCase(loginWithEncryptedDataAsync.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loginStatus = Status.IDLE
        message.error(payload)
      })
      .addCase(loginWithEncryptedDataAsync.fulfilled, (state, { payload }: PayloadAction<any>) => {
        auth.login(
          payload.token,
          payload.user.personName.trim() ? payload.user.personName : payload.user.userName,
          payload.expiry,
        )
        state.loginStatus = Status.IDLE
        state.user = payload
      })
      .addCase(fetchUserAccountAsync.pending, (state) => {
        state.loginStatus = Status.LOADING
      })
      .addCase(fetchUserAccountAsync.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loginStatus = Status.ERROR
        message.error(payload)
      })
      .addCase(fetchUserAccountAsync.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loginStatus = Status.IDLE
        state.account = payload
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.requestStatus = Status.LOADING
      })
      .addCase(resetPasswordAsync.rejected, (state, { payload }: PayloadAction<any>) => {
        state.requestStatus = Status.ERROR
        message.error(payload)
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.requestStatus = Status.IDLE
        state.resetPasswordModalOpen = false
      })
      .addCase(fetchBypassMyAccountUrlAsync.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.requestStatus = Status.IDLE
        state.byPassMyAccountUrl = payload
      })
  },
})

export const getEncryptedData = createSelector(
  (state: IRootState) => state.auth.byPassMyAccountUrl,
  (byPassUrl) => {
    if (byPassUrl?.url) {
      const url = new URL(byPassUrl?.url)
      const urlParams = new URLSearchParams(url.searchParams)
      const encryptedData = urlParams.get('EncryptedData')
      return encryptedData
    }
    return null
  },
)

export const { logout, setUser, openResetPasswordModal } = loginSlice.actions
export default loginSlice.reducer
