export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
}

export enum Session {
  KEY = 'pci_token',
  USERNAME = 'pci_username',
  EXPIRY = 'pci_expiry',
  ENCRYPTED_DATA = 'pci_encrypted',
  LINKGEN_KEY = 'pci_linkgen',
  DISCLAIMER_AGREED = 'pci_discalimer_agreed',
  TIMEOUT = 1440,
}

// Routes
export enum RoutePaths {
  ROOT = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  COMPARISON = '/comparison',
  REPORT = '/report',
  SELECT_CLASSES = '/select-classes',
  DASHBOARD = '/dashboard',
  PRIVATE = 'private',
  REST = '*',
}

// Filters
export enum CompareBy {
  COMPANY = 'Company',
  FEATURE = 'Feature',
}

export enum PreviewFilter {
  ICON = 'Icon',
  TEXT = 'Text',
  FULLTEXT = 'Full Text',
}

export enum CategoryFilter {
  MOST_POPULAR = 'most_popular',
  WATER_PERILS = 'water_perils',
  KEY_SELLING_FEATURES = 'key_selling',
  TRAINING = 'AMS 101',
  CUSTOM = 'custom',
  ALL = 'all',
}

export enum ScreenMode {
  REPORT = 'Report',
  COMPARISON = 'Comparison',
}

export enum ThemeColour {
  PRIMARY_COLOUR = '#f1c400',
}

export enum DateFormat {
  AU = 'DD/MM/YYYY',
  UTC = 'YYYY-MM-DD',
}

export enum PolicyStatus {
  IS_APPROVED = 'IsApproved',
  IS_NEW = 'IsNew',
  IS_EXPIRED = 'IsExpired',
  IS_PENDING = 'IsPending',
  IS_EXPRESSCOVER = 'IsExpressCover',
}

export enum ComparisonColumn {
  MAX_SELECTED_LENGTH = 8,
  COLUMN_WIDTH = 320,
  COLUMN_WIDTH_MOBILE = 250,
  COLUMN_WIDTH_NAME_MOBILE = 120,
  LEFT = 'goLeft',
  RIGHT = 'goRight',
  HANDLE_MOTION_EASE = 100,
}

export enum ComparisonTableHeading {
  RATING = 'ClaimsComparison Rating',
}

export enum ClassLines {
  PERSONAL = 'Personal',
  COMMERCIAL = 'Commercial',
  BUSINESS = 'Business',
  PROFESSIONAL = 'Professional',
  RURAL = 'Rural',
  UNCATEGORISED = 'Uncategorised',
  ALL_PACKAGE_SECTION = 'All Package Sections',
}

export enum BreakPoints {
  MOBILE = 575,
  TABLET = 768,
  DESKTOP = 991,
}

export enum IconLables {
  YES = 'yes',
  YES_ALT = 'yes-alt',
  NO = 'no',
  NO_ALT = 'no-alt',
  OPTIONAL = 'optional',
  OPTIONAL_ALT = 'optional-alt',
  OPTIONAL_LIMIT_DOLLAR = 'optional-limited-dollar',
  OPTIONAL_LIMIT_POUND = 'optional-limited-pound',
  SUBLIMIT_LIMIT_ALT = 'sub-limited-alt',
  SUBLIMIT_LIMIT_ALT_ADDITIONAL = 'sub-limited-alt-additional',
  LIMITED_ALT = 'limited-alt',
  EXCLUDE = 'exclude',
  EXCLUDE_ALT = 'exclude-alt',
  NO_EXCLUSION = 'no-exclusion',
  NO_EXCLUSION_ALT = 'no-exclusion-alt',
  NOT_APPLICABLE = 'not-applicable',
  NOT_APPLICABLE_ALT = 'not-applicable-alt',
  NOT_STATED = 'not-stated',
  NOT_STATED_ALT = 'not-stated-alt',
  EMPTY = 'empty',
  DEFAULT = 'default',
}

export enum IconTexts {
  YES = 'YES',
  NO = 'NO',
  OPTIONAL = 'Optional',
  LIMIT = 'Limit',
  LIMITED = 'Limited',
  EXCLUSION = 'Exclusion',
  NO_EXCLUSION = 'No exclusion',
  NOT_APPLICABLE = 'Not applicable',
  NOT_STATED = 'Not stated',
  REFER_TO_TEXT = 'Refer to text',
  REFER_TO_INSURER = 'Ref. to Insurer',
}

export enum ParamKeys {
  SUBSCRIPTION_KEY = 'subscription-key',
  ENCRYPTED_DATA = 'EncryptedData',
  KEY = 'key',
  LATEST_POLICIES_COUNT = 10,
}

export enum FileFormat {
  XLS = 'xls',
  PDF = 'pdf',
}

export enum PolicyStatusMessage {
  PENDING = 'This wording is now expired, a new comparison for the updated version will be available shortly.',
}

export enum UserActivityLog {
  ViewPointToConsider = 'ViewPointToConsider',
  DeselectPolicy = 'DeselectPolicy',
  DeselectFeature = 'DeselectFeature',
}
